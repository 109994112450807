<template>
  <main>
    <CForm>
      <CCard>
        <CAlert
            id="ChooseNotice"
            v-if="(ChooseSlugs.length > 0)"
            color="info"
            :fade="false"
        >
          <span class="text">
            {{ $t('Global.SelectedLabel') }}
            <span class="font-weight-bold">{{ ChooseSlugs.length }}</span>
            {{ $t('Global.ItemUnit') }}
          </span>
          <div class="ButtonGroup">
            <CButton color="light" size="sm" class="mr-2" @click="ChooseSlugs = [];CheckAllValue = false">{{ $t('Global.Cancel') }}</CButton>
            <CButton v-if="$store.state.user.permission.Permission.Post.Items.includes('D')" color="danger" size="sm" @click="AlertModel = true">{{ $t('Global.Delete') }}</CButton>
          </div>
        </CAlert>
        <CCardHeader>
          <CRow class="d-flex align-items-center">
            <CCol col="6">
              <h5 class="mb-0">{{ $t('Navigation.Content/Navbar') }}</h5>
            </CCol>
            <CCol col="6" class="d-flex justify-content-end">
              <CButton color="success" variant="outline" shape="pill" class="mr-2" type="button" @click="AddModal()">
                <CIcon size="sm" class="mr-1" name="cil-plus"/>{{ $t('Navigation.Content/AddNavbar') }}
              </CButton>
            </CCol>
          </CRow>
        </CCardHeader>
        <CCardBody class="p-0">
          <CDataTable
              id="PostList"
              :items="List"
              :fields="Field"
              :loading="Loading"
              :noItemsView="noItemsView"
              responsive
              hover
          >
            <template #Checkbox-header>
              <label class="mb-0">
                <input type="checkbox" id="CheckAll" v-model="CheckAllValue" @change="CheckAll()">
              </label>
            </template>
            <template #Checkbox="{item}">
              <td class="text-center">
                <label class="mb-0">
                  <input type="checkbox" :id="'Checkbox-Post-' + item.Slug" :value="item.Slug" v-model="ChooseSlugs">
                </label>
              </td>
            </template>
            <template #Status="{item}">
              <td>
                <CBadge :color="(item.Status === 1 ? 'success' : 'danger')">
                  {{ $t('Product.Status.' + item.Status) }}
                </CBadge>
              </td>
            </template>
            <template #Status="{item}">
              <td>
                <CBadge :color="(item.Status === 1 ? 'success' : 'danger')">
                  {{ $t('Product.Status.' + item.Status) }}
                </CBadge>
              </td>
            </template>
            <template #CreateTime="{item}">
              <td>
                {{ $dayjs(item.CreateTime).format('YYYY-MM-DD HH:mm:ss') }}
              </td>
            </template>
            <template #Action="{item}">
              <td>
                <CButton v-if="$store.state.user.permission.Permission.Post.Items.includes('W')" color="info" size="sm" class="mr-1" @click="EditModal(item.Slug)">
                  <CIcon name="cil-pencil" class="c-icon-custom-size mr-1"/> {{ $t('Post/List.Edit') }}
                </CButton>
                <CButton color="secondary" size="sm" class="mr-1" v-c-tooltip="$t('Global.Duplicate')" @click="Duplicate(item.Slug)">
                  <CIcon name="cil-copy" class="c-icon-custom-size mr-1" />
                </CButton>
              </td>
            </template>
          </CDataTable>
        </CCardBody>
        <CCardFooter>
          <CPagination
              :activePage.sync="Pagination.Page"
              :pages.sync="Pagination.TotalPages"
              @update:activePage="ChangePage()"
          />
        </CCardFooter>
      </CCard>
    </CForm>
    <CModal id="NavbarDataModal" class="ActionModel" size="lg" :show.sync="NavbarDataModal" :closeOnBackdrop="false">
      <CInput label="選單名稱" v-model="Name" />
      <CSelect label="選單位置" description="若選擇「頂部導覽列」或「底部導覽列」，則會直接覆蓋「佈景管理 > 頂部導覽列」或「佈景管理 > 底部導覽列」" :options="PositionOptions" v-model="Position" :value.sync="Position" :placeholder="$t('Global.PleaseSelect')" />
      <CInput v-if="Position === 'CUSTOM'" label="選單位置名稱" v-model="CustomPosition" />
      <div class="font-weight-bold d-flex justify-content-between mb-2">
        <label class="d-block mb-0">選單內容</label>
        <div>
          <CButton color="success" size="sm" @click="AddMenuItem()">
            <CIcon name="cil-plus" class="c-icon-custom-size mr-1" />{{ $t('Global.Add') }}
          </CButton>
        </div>
      </div>
      <CAlert v-if="NavbarData.length <= 0" :fade="false" color="secondary">
        <CIcon name="cil-bell" /> 目前沒有選單，請按下「新增」開始加入選單內容。
      </CAlert>
      <Draggable
        v-model="NavbarData"
        class="list-group"
        tag="ul"
        v-bind="DragOptions"
        @start="drag = true"
        @end="drag = false"
      >
        <CCard v-for="(item, index) in NavbarData" :key="index">
          <CCardHeader class="d-flex align-items-center">
            <div class="flex-grow-1" style="cursor: pointer;" @click="(MenuAccordion = MenuAccordion === index ? false : index)">
              {{ item.Name }}
            </div>
            <CButton size="sm" color="danger" @click="DeleteMenuItem(`${index}`)">{{ $t('Global.Delete') }}</CButton>
          </CCardHeader>
          <CCollapse :show="MenuAccordion === index">
            <CCardBody>
              <div class="mb-3">
                <CInput v-model="item.Name" :label="$t('System/Themes.Menu.Name')"/>
                <CInput v-model="item.SubTitle" :label="$t('System/Themes.Menu.SubTitle')"/>
                <CInput v-model="item.URL" :label="$t('System/Themes.Menu.URL')"/>
                <CInput v-model="item.Cover" label="圖示">
                  <template #append>
                    <CButton type="button" color="dark" @click="OpenMediaStore(index)">{{ $t('Global.Browse') }}</CButton>
                  </template>
                </CInput>
                <CSelect :value.sync="item.Target" :label="$t('System/Themes.Menu.Target')" :options="[{ label: $t('System/Themes.Menu.Target/Self'), value: '_self' }, { label: $t('System/Themes.Menu.Target/Blank'), value: '_blank' }]"/>
                <div class="w-100 d-flex flex-column my-2 mb-4">
                  <label :for="'MenuSwitch' + index">啟用選單</label>
                  <CSwitch
                    color="success"
                    shape="pill"
                    :checked.sync="item.Open"
                    :id="'MenuSwitch' + index"
                  />
                </div>
                <div class="font-weight-bold d-flex justify-content-between mb-2">
                  <label class="d-block mb-0">子選單內容</label>
                  <div>
                    <CButton color="info" size="sm" @click="AddSubMenuItem(index)">
                      <CIcon name="cil-plus" class="c-icon-custom-size mr-1" />{{ $t('Global.Add') }}{{ $t('System/Themes.Menu.SubMenu') }}
                    </CButton>
                  </div>
                </div>
              </div>
              <div>
                <Draggable
                  v-model="item.SubMenu"
                  v-bind="DragOptions"
                  @start="drag = true"
                  @end="drag = false"
                >
                  <CCard v-for="(subItem, subIndex) in item.SubMenu" :key="subIndex">
                    <CCardHeader class="d-flex align-items-center">
                      <div class="flex-grow-1" style="cursor: pointer;" @click="(SubMenuAccordion = SubMenuAccordion === `${index}-${subIndex}` ? false : `${index}-${subIndex}`)">
                        {{ subItem.Name }}
                      </div>
                      <CButton color="danger" size="sm" @click="DeleteMenuItem(`${index}-${subIndex}`)">{{ $t('Global.Delete') }}</CButton>
                    </CCardHeader>
                    <CCollapse :show="SubMenuAccordion === `${index}-${subIndex}`">
                      <CCardBody>
                        <div class="mb-3">
                          <CInput v-model="subItem.Name" :label="$t('System/Themes.Menu.Name')"/>
                          <CInput v-model="subItem.URL" :label="$t('System/Themes.Menu.URL')"/>
                          <CInput v-model="subItem.Cover" label="圖示">
                            <template #append>
                              <CButton type="button" color="dark" @click="OpenMediaStore(`${index}-${subIndex}`)">{{ $t('Global.Browse') }}</CButton>
                            </template>
                          </CInput>
                          <CSelect :value.sync="subItem.Target" :label="$t('System/Themes.Menu.Target')" :options="[{ label: $t('System/Themes.Menu.Target/Self'), value: '_self' }, { label: $t('System/Themes.Menu.Target/Blank'), value: '_blank' }]"/>
                          <div class="w-100 d-flex flex-column my-2 mb-4">
                            <label :for="'MenuSwitch' + index + subIndex">啟用選單</label>
                            <CSwitch
                              color="success"
                              shape="pill"
                              :checked.sync="subItem.Open"
                              :id="'MenuSwitch' + index + subIndex"
                            />
                          </div>
                          <div class="font-weight-bold d-flex justify-content-between mb-2">
                            <label class="d-block mb-0">第二層子選單內容</label>
                            <div>
                              <CButton color="info" size="sm" @click="AddSecondSubMenuItem(index, subIndex)">
                                <CIcon name="cil-plus" class="c-icon-custom-size mr-1" />{{ $t('Global.Add') }}{{ $t('System/Themes.Menu.SubMenu') }}
                              </CButton>
                            </div>
                          </div>
                        </div>
                        <div>
                          <Draggable
                            v-model="subItem.SubMenu"
                            v-bind="DragOptions"
                            @start="drag = true"
                            @end="drag = false"
                          >
                            <CCard v-for="(secondSubItem, secondSubIndex) in subItem.SubMenu" :key="secondSubIndex">
                              <CCardHeader class="d-flex align-items-center">
                                <div class="flex-grow-1" style="cursor: pointer;" @click="(SecondSubMenuAccordion = SecondSubMenuAccordion === `${index}-${subIndex}-${secondSubIndex}` ? false : `${index}-${subIndex}-${secondSubIndex}`)">
                                  {{ secondSubItem.Name }}
                                </div>
                                <CButton color="danger" size="sm" @click="DeleteMenuItem(`${index}-${subIndex}-${secondSubIndex}`)">{{ $t('Global.Delete') }}</CButton>
                              </CCardHeader>
                              <CCollapse :show="SecondSubMenuAccordion === `${index}-${subIndex}-${secondSubIndex}`">
                                <CCardBody>
                                  <CInput v-model="secondSubItem.Name" :label="$t('System/Themes.Menu.Name')"/>
                                  <CInput v-model="secondSubItem.URL" :label="$t('System/Themes.Menu.URL')"/>
                                  <CInput v-model="secondSubItem.Cover" label="圖示">
                                    <template #append>
                                      <CButton type="button" color="dark" @click="OpenMediaStore(`${index}-${subIndex}-${secondSubIndex}`)">{{ $t('Global.Browse') }}</CButton>
                                    </template>
                                  </CInput>
                                  <CSelect :value.sync="secondSubItem.Target" :label="$t('System/Themes.Menu.Target')" :options="[{ label: $t('System/Themes.Menu.Target/Self'), value: '_self' }, { label: $t('System/Themes.Menu.Target/Blank'), value: '_blank' }]"/>
                                  <div class="w-100 d-flex flex-column my-2">
                                    <label :for="'MenuSwitch' + index + subIndex + secondSubIndex">啟用選單</label>
                                    <CSwitch
                                      color="success"
                                      shape="pill"
                                      :checked.sync="secondSubItem.Open"
                                      :id="'MenuSwitch' + index + subIndex + secondSubIndex"
                                    />
                                  </div>
                                </CCardBody>
                              </CCollapse>
                            </CCard>
                          </Draggable>
                        </div>
                      </CCardBody>
                    </CCollapse>
                  </CCard>
                </Draggable>
              </div>
            </CCardBody>
          </CCollapse>
        </CCard>
      </Draggable>
      <template #header-wrapper>
        <header class="modal-header border-bottom-0">
          <h5 class="modal-title">{{ Action === 'Add' ? $t('Navigation.Content/AddNavbar') : $t('Navigation.Content/Navbar') + ': ' + Name }}</h5>
          <CButtonClose @click="NavbarDataModal = false"/>
        </header>
      </template>
      <template #footer-wrapper>
        <CElementCover
          v-if="(Submit === true || Loading === true)"
          :boundaries="[{ sides: ['center', 'center'], query: '#SaveSubmit' }]"
          :opacity="0.8"
        >
          <CSpinner size="sm" color="primary"/>
        </CElementCover>
        <footer class="modal-footer border-top-0">
          <CButton @click="NavbarDataModal = false" color="light">{{ $t('Global.Cancel') }}</CButton>
          <CButton id="SaveSubmit" :disabled="Submit === true || Loading === true" @click="Save()" color="success">
            {{ $t('Global.Confirm') }}
          </CButton>
        </footer>
      </template>
    </CModal>
    <CModal id="AlertModel" class="ActionModel" :show.sync="AlertModel" :centered="true" :closeOnBackdrop="!Submit">
      <CAlert color="danger">
        <CIcon name="cil-bell" /> {{ $t('Global.ConfirmDelete') }}
      </CAlert>
      <template #header-wrapper>
        <header class="modal-header border-bottom-0">
          <h5 class="modal-title">{{ $t('Global.Delete') }}</h5>
          <CButtonClose @click="AlertModel = false" />
        </header>
      </template>
      <template #footer-wrapper>
        <CElementCover
            v-if="(Submit === true)"
            :boundaries="[{ sides: ['center', 'center'], query: '#DeleteSubmit' }]"
            :opacity="0.8"
        >
          <CSpinner size="sm" color="primary"/>
        </CElementCover>
        <footer class="modal-footer border-top-0">
          <CButton @click="AlertModel = false" color="light">{{ $t('Global.Cancel') }}</CButton>
          <CButton id="DeleteSubmit" @click="Delete()" color="danger">
            {{ $t('Global.Confirm') }}
          </CButton>
        </footer>
      </template>
    </CModal>
    <MediaStore :Config="MediaStoreConfig" @OK="SetImage()" />
  </main>
</template>

<route>
{
  "meta": {
    "label": "自訂選單"
  }
}
</route>

<script>
export default {
  name: 'CustomNavbar',
  layout: 'manage',
  components: {
    MediaStore: () => import('@/components/public/Media'),
    Draggable: () => import('vuedraggable'),
  },
  data () {
    return {
      List: [],
      CategoryList: [],
      SelectCategories: [],
      AlertModel: false,
      NavbarDataModal: false,
      BulkEditData: {
        Status: true
      },
      NavbarData: [],
      Name: '',
      Position: 'CUSTOM',
      CustomPosition: '',
      ChooseSlugs: [],
      CurrentSlug: null,
      CheckAllValue: false,
      Pagination: {
        Page: 1,
        PerPage: 10,
        TotalItems: 0,
        TotalPages: 0
      },
      Action: 'Add',
      Order: 'nto',
      MenuAccordion: '',
      SubMenuAccordion: '',
      SecondSubMenuAccordion: '',
      drag: false,
      Loading: false,
      Submit: false,
      noItemsView: {
        noResults: this.$t('Post/List.NoResults'),
        noItems: this.$t('Post/List.NoItems')
      },
      PositionOptions: [
        {
          label: '自定義位置名稱',
          value: 'CUSTOM'
        },
        {
          label: '頂部導覽列',
          value: 'HEADER'
        },
        {
          label: '底部導覽列',
          value: 'FOOTER'
        }
      ],
      ShowFilter: 'All',
      MediaStoreConfig: {
        Display: false,
        ChooseImageList: [],
        Multiple: false
      },
    }
  },
  computed: {
    Field () {
      return [
        { key: 'Checkbox', label: '' },
        { key: 'Name', label: this.$t('Post/List.Name') },
        { key: 'Slug', label: '選單位置' + this.$t('Post/List.Slug') },
        { key: 'Status', label: this.$t('Post/List.Status') },
        { key: 'CreateTime', label: this.$t('Global.CreateTime') },
        { key: 'Action', label: '' }
      ]
    },
    DragOptions () {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      }
    }
  },
  mounted() {
    this.$Progress.start()
    this.Init().then(() => {
      this.$Progress.finish()
    }).catch((err) => {
      this.$Progress.fail()
      this.$notify({
        group: 'notify',
        title: this.$t('Message.Error'),
        text: this.$t('Message.Global/ErrorMessage') + err.msg,
        type: (err.type ? err.type : 'error')
      })
    })
  },
  methods: {
    Init() {
      return Promise.all([
        this.GetList(),
      ]).catch((err) => {
        throw err
      })
    },
    GetList() {
      let RequestQuery = {
        taxonomy: 'Navbar',
        page: this.$route.query.page || this.Pagination.Page,
        perPage: this.$route.query.perPage || this.Pagination.PerPage,
        order: this.$route.query.order || this.Order
      }
      this.List = []
      this.Loading = true
      this.FilterModel = false
      return this.$store.dispatch('InnerRequest', {
        url: '/content/list',
        method: 'get',
        params: RequestQuery
      }).then(({data}) => {
        this.Loading = false
        this.List = data.list
        this.Pagination = data.pagination
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    ChangePage() {
      const query = {
        page: this.Pagination.Page,
        perPage: this.Pagination.PerPage,
        order: this.Order,
      }
      this.$router.replace({
        path: '/content/navbar',
        query
      }).then(() => {
        this.Init()
      })
    },
    Delete() {
      this.Submit = true
      return this.$store.dispatch('InnerRequest', {
        url: '/content/delete',
        method: 'post',
        data: {
          taxonomy: 'Navbar',
          slug: this.ChooseSlugs
        }
      }).then(() => {
        this.Submit = false
        this.AlertModel = false
        this.Init()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.Global/DeleteSuccess'),
          type: 'success'
        })
      }).catch((err) => {
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Global/DeleteFail') + err.msg,
          type: 'error'
        })
      })
    },
    CheckAll() {
      if (this.CheckAllValue === true) {
        this.ChooseSlugs = this.List.map(data => {
          return data.Slug
        })
      } else {
        this.CheckAllValue = false
        this.ChooseSlugs = []
      }
    },
    AddModal() {
      this.Action = 'Add'
      this.Name = '新的自訂選單' + (parseInt(this.Pagination.TotalItems) + 1)
      this.NavbarData = []
      this.NavbarDataModal = true
      this.CurrentSlug = null
      this.CustomPosition = ''
      this.Position = 'CUSTOM'
    },
    EditModal(slug) {
      this.Action = 'Edit'
      this.CurrentSlug = slug
      this.NavbarData = []
      this.NavbarDataModal = true
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/content/detail',
        method: 'get',
        params: {
          slug,
          taxonomy: 'Navbar'
        }
      }).then(({data}) => {
        this.Loading = false
        this.Name = data.detail.Name
        this.NavbarData = data.detail.Content && JSON.parse(data.detail.Content) || []
        this.CustomPosition = data.detail.Slug
        this.Position = data.detail.OtherName
      }).catch((err) => {
        this.Loading = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Global/ErrorMessage') + err.msg,
          type: 'error'
        })
      })
    },
    AddMenuItem() {
      this.NavbarData.push({
        Name: `主選單#${this.NavbarData.length + 1}`,
        SubTitle: '',
        URL: '',
        Target: '_self',
        Open: true,
        Cover: '',
        SubMenu: []
      })
    },
    AddSubMenuItem(key) {
      if (!this.NavbarData[key].SubMenu) {
        this.$set(this.NavbarData[key], 'SubMenu', [])
      }
      this.NavbarData[key].SubMenu.push({
        Name: `子選單#${this.NavbarData[key].SubMenu.length + 1}`,
        URL: '',
        Target: '_self',
        Open: true,
        Cover: '',
        SubMenu: []
      })
    },
    AddSecondSubMenuItem(key, subKey) {
      if (!this.NavbarData[key].SubMenu[subKey]) {
        this.$set(this.NavbarData[key].SubMenu[subKey], 'SubMenu', [])
      }
      this.NavbarData[key].SubMenu[subKey].SubMenu.push({
        Name: `第二層子選單#${this.NavbarData[key].SubMenu[subKey].SubMenu.length + 1}`,
        URL: '',
        Target: '_self',
        Open: true,
        Cover: '',
        SubMenu: []
      })
    },
    DeleteMenuItem(key) {
      const keys = key.split('-')
      if (keys.length === 3) {
        // 第二層子選單刪除
        this.NavbarData[keys[0]].SubMenu[keys[1]].SubMenu.splice(keys[2], 1)
      } else if(keys.length === 2) {
        // 第一層子選單刪除
        this.NavbarData[keys[0]].SubMenu.splice(keys[1], 1)
      } else {
        // 主選單刪除
        this.NavbarData.splice(keys[0], 1)
      }
    },
    Save() {
      let Execute
      switch (this.Action) {
        case 'Edit':
          Execute = this.Edit()
          break
        case 'Add':
          Execute = this.Add()
          break
        default:
          this.$notify({
            group: 'notify',
            title: this.$t('Message.Error'),
            text: this.$t('Message.Post/SaveFail-1'),
            type: 'error'
          })
          return
      }
      this.Loading = true
      Execute.then((result) => {
        this.Loading = false
        this.NavbarDataModal = false
        this.Init()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: result.msg,
          type: 'success'
        })
      }).catch((err) => {
        console.log(err)
        this.Loading = false
        this.Submit = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error') + '[' + err.code  + ']',
          text: this.$t('Message.Post/SaveFail-2') + err.msg,
          type: 'error'
        })
      })
    },
    Add() {
      return Promise.all([
        this.$store.dispatch('InnerRequest', {
          url: '/system/menu/set',
          method: 'post',
          data: {
            Position: this.Position === 'CUSTOM' ? this.CustomPosition : this.Position,
            NavbarData: this.NavbarData
          }
        }),
        this.$store.dispatch('InnerRequest', {
          url: '/content/add',
          method: 'post',
          data: {
            taxonomy: 'Navbar',
            Content: JSON.stringify(this.NavbarData),
            Name: this.Name,
            Slug: this.Position === 'CUSTOM' ? this.CustomPosition : this.Position,
            OtherName: this.Position,
            Status: 1
          }
        })
      ]).then(({data}) => {
        return {
          ...data,
          msg: '新增選單成功'
        }
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    Edit() {
      return Promise.all([
        this.$store.dispatch('InnerRequest', {
          url: '/system/menu/set',
          method: 'post',
          data: {
            Position: this.Position === 'CUSTOM' ? this.CustomPosition : this.Position,
            NavbarData: this.NavbarData
          }
        }),
        this.$store.dispatch('InnerRequest', {
          url: '/content/edit',
          method: 'post',
          data: {
            taxonomy: 'Navbar',
            slug: this.CurrentSlug,
            updateData: {
              Content: JSON.stringify(this.NavbarData),
              Name: this.Name,
              Slug: this.Position === 'CUSTOM' ? this.CustomPosition : this.Position,
              OtherName: this.Position,
              Status: 1
            }
          }
        })
      ]).then(() => {
        return {
          msg: '編輯選單成功'
        }
      }).catch((err) => {
        throw err
      })
    },
    Duplicate(slug) {
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/content/duplicate',
        method: 'get',
        params: {
          taxonomy: 'Navbar',
          slug
        }
      }).then(() => {
        this.GetList()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.Global/DuplicateSuccess'),
          type: 'success'
        })
      }).catch((error) => {
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Global/DuplicateFail') + error.msg,
          type: 'error'
        })
      })
    },
    OpenMediaStore(index) {
      this.MediaStoreConfig.ChooseImageList = []
      this.MediaStoreConfig.Display = !this.MediaStoreConfig.Display
      this.MediaStoreConfig.Multiple = false
      this.ChooseImageIndex = index.toString()
    },
    async SetImage() {
      const [index, subIndex, secondSubIndex] = this.ChooseImageIndex && this.ChooseImageIndex.split('-') || []
      let imgURL = this.$store.state.user.permission.StaticDomain + this.MediaStoreConfig.ChooseImageList
      if (!this.$store.state.user.permission.StaticDomain) {
        imgURL = await this.$store.state.userProjectApp.storage().ref().child(this.MediaStoreConfig.ChooseImageList).getDownloadURL()
      }
      if (secondSubIndex) {
        // 第二層子選單圖片
        this.$set(this.NavbarData[index].SubMenu[subIndex].SubMenu[secondSubIndex], 'Cover', imgURL)
      } else if(subIndex) {
        // 第一層子選單圖片
        this.$set(this.NavbarData[index].SubMenu[subIndex], 'Cover', imgURL)
      } else {
        // 主選單圖片
        this.$set(this.NavbarData[index], 'Cover', imgURL)
      }
    },
  }
}
</script>

<style>
#ChooseNotice {
  position: absolute;
  z-index: 10;
  width: 100%;
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
  padding: 0.5rem 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#PostList thead th:first-child {
  text-align: center;
}
#PostList tbody td:first-child {
  vertical-align: middle;
}
</style>
